// -------- override less variables -----------
@primary-color: #6FC4C2;
@text-color: #333333;
@text-color-secondary: #aaaaaa;
@label-color: #333333;
@error-color: #FF7980;
@layout-body-background: #ffffff;
@divider-color: #D9D9D9;
@process-tail-color: #D9D9D9;
@border-radius-base: 8px;
@checkbox-border-radius: 4px;
@font-family: 'Poppins', sans-serif;
@slider-handle-color: fade(@primary-color, 80%);
@slider-handle-color-focus: @primary-color;
@slider-handle-color-hover: @primary-color;
@slider-track-background-color: fade(@primary-color, 70%);
@slider-track-background-color-hover: fade(@primary-color, 90%);
@slider-rail-background-color: fade(@primary-color, 30%);
@slider-rail-background-color-hover: fade(@primary-color, 50%);
@btn-default-color: @primary-color;
@btn-height-lg: 50px;
@input-height: 50px;
@border-color-split: #EFEFEF;
@btn-square-only-icon-size-lg: 23px;

//custom variables
@white: #ffffff;
@secondary-color: #F08B5E;

@dark-100: #000000;
@dark-80: #333333;

@secondary-100-color: #666666;
@secondary-80-color: #7A7A7A;
@secondary-60-color: #AAAAAA;
@secondary-40-color: #D9D9D9;
@secondary-20-color: #EFEFEF;

@primary-100-color: #6FC4C2;
@primary-80-color: #6FAFC2;
@primary-40-color: #BBEDEC;

@success-100-color: #61CE70;
@success-60-color: #A8E8B1;
@success-20-color: #DFFFE4;

@danger-100-color: #FB353F;
@danger-80-color: #E15440;
@danger-40-color: #FF7980;

@background-yellow: fade(#F3E1D6, 50%);
@border-yellow: #F3E1D6;


:root {
  // -------- override less variables -----------
  --primary-color: @primary-color;
  --text-color: @text-color;
  --text-color-secondary: @text-color-secondary;
  --label-color: @label-color;
  --error-color: @error-color;
  --layout-body-background: @layout-body-background;
  --divider-color: @divider-color;
  --process-tail-color: @process-tail-color;
  --border-radius-base: @border-radius-base;
  --checkbox-border-radius: @checkbox-border-radius;
  --font-family: @font-family;
  --slider-handle-color: fade(@primary-color, 80%);
  --slider-handle-color-focus: @primary-color;
  --slider-handle-color-hover: @primary-color;
  --slider-track-background-color: fade(@primary-color, 70%);
  --slider-track-background-color-hover: fade(@primary-color, 90%);
  --slider-rail-background-color: fade(@primary-color, 30%);
  --slider-rail-background-color-hover: fade(@primary-color, 50%);
  --btn-default-color: @primary-color;
  --btn-height-lg: @btn-height-lg;
  --input-height: @input-height;
  --border-color-split: @border-color-split;
  --btn-square-only-icon-size-lg: @btn-square-only-icon-size-lg;

  //custom variables
  --white: @white;
  --secondary-color: @secondary-color;

  --dark-100: @dark-100;
  --dark-80: @dark-80;

  --secondary-100-color: @secondary-100-color;
  --secondary-80-color: @secondary-80-color;
  --secondary-60-color: @secondary-60-color;
  --secondary-40-color: @secondary-40-color;
  --secondary-20-color: @secondary-20-color;

  --primary-100-color: @primary-100-color;
  --primary-80-color: @primary-80-color;
  --primary-40-color: @primary-40-color;

  --success-100-color: @success-100-color;
  --success-60-color: @success-60-color;
  --success-20-color: @success-20-color;

  --danger-100-color: @danger-100-color;
  --danger-80-color: @danger-80-color;
  --danger-40-color: @danger-40-color;

  --background-yellow: @background-yellow;
  --border-yellow: @border-yellow;
}